import '../styles/index.scss';
import Raf from './raf.js'

if (process.env.NODE_ENV === 'development') {
  require('../index.html');
}

Math.lerp = function (value1, value2, amount) {
    amount = amount < 0 ? 0 : amount;
    amount = amount > 1 ? 1 : amount;
    return value1 + (value2 - value1) * amount;
};

const RANGE_MOVE_LEFT_RIGHT = 60;

class Question {
    constructor() {
        this.pos = { x: 0, y: 0 };
        this.posTo = { x: 0, y: 0 };
        this.cercle = document.querySelector('#js-cercle');
        this.circlePos = this.cercle.getBoundingClientRect();
        this.$el = document.querySelector('.Lopup');

        document.addEventListener('mousemove', this.onMouseMove.bind(this), false);

        document.querySelectorAll('.js-close-lopup').forEach((elm)=>{
            elm.addEventListener('click', this.onCloseClick.bind(this))
        });

        Raf.add( this.update.bind(this) );

        setTimeout(() => {
            this.$el.classList.add('is-active');
        }, 6000);
    }

    onCloseClick(){
        this.$el.classList.remove('is-active');
    }

    onMouseMove(e){
        this.posTo.x = e.clientX - this.circlePos.left - (this.circlePos.width / 2);
        this.posTo.x = Math.min(RANGE_MOVE_LEFT_RIGHT, Math.max(-RANGE_MOVE_LEFT_RIGHT, this.posTo.x));
    }

    lerp(value1, value2, amount) {
        amount = amount < 0 ? 0 : amount;
        amount = amount > 1 ? 1 : amount;
        return value1 + (value2 - value1) * amount;
    }

    update(){
        this.pos.x = Math.round(this.lerp(this.pos.x, this.posTo.x, 0.06) * 1000) / 1000

        if (this.pos.x % 1) {
            const normalizedCirclePos = this.pos.x / RANGE_MOVE_LEFT_RIGHT;
            this.circleScale = 1 - Math.abs(normalizedCirclePos / 2)
            this.cercle.style.transform = `translate(${normalizedCirclePos * 55}px, 0px) scale(${this.circleScale})`
        }
    }
}

new Question();